/* eslint-disable */
/**
 * !!!!!!!! DO NOT MODIFY THIS FILE BY HAND !!!!!!!!
 * This file was automatically generated, and any changes made here will be overwritten.
 * @plugins/heft-editor-ui-module-extractor is used to extract this file from the src/index.ts by looking for EditorUIModule export. 
 * To add a module export const of type EditorUIModule and run "rush build" to regenerate this file.
 * 
 */
import { richTextModule } from '@editor/ui-richtext';
import { userModule } from '@editor/ui-user';
import { complexModuleModule } from '@module-examples/complex-module-editor-ui';
import { complexPageModule } from '@module-examples/complex-page-editor-ui';
import { externalToolModule } from '@module-examples/external-tool-editor-ui';
import { widgetModuleModule } from '@module-examples/widget-module-editor-ui';
import { articlesBoxModule } from '@modules/articles-box-editor-ui';
import { assemblerModule } from '@modules/assembler-editor-ui';
import { assemblerFeedModule } from '@modules/assembler-feed-editor-ui';
import { authorModule } from '@modules/author-editor-ui';
import { backgroundArticleModule } from '@modules/background-article-editor-ui';
import { catToolModule } from '@modules/cat-tool-editor-ui';
import { channelModule } from '@modules/channel-editor-ui';
import { channelizerModule } from '@modules/channelizer-editor-ui';
import { ChipWidgetModule } from '@modules/chip-widget-editor-ui';
import { ctaButtonModule } from '@modules/cta-button-editor-ui';
import { galleryModule } from '@modules/gallery-editor-ui';
import { imageModule } from '@modules/image-editor-ui';
import { jobModule } from '@modules/job-editor-ui';
import { kimModule } from '@modules/kim-editor-ui';
import { linksBoxModule } from '@modules/links-box-editor-ui';
import { lockingModule } from '@modules/locking-editor-ui';
import { contentModule } from '@modules/news-article-editor-ui';
import { textContentBlockModule } from '@modules/news-article-editor-ui-article-content-text';
import { partnerModule } from '@modules/partner-editor-ui';
import { seoWhitelistModule } from '@modules/seo-whitelist-editor-ui';
import { socialModule } from '@modules/social-editor-ui';
import { taggedPageModule } from '@modules/tagged-page-editor-ui';
import { videoModule } from '@modules/video-editor-ui';

export const modules = [richTextModule, userModule, complexModuleModule, complexPageModule, externalToolModule, widgetModuleModule, articlesBoxModule, assemblerModule, assemblerFeedModule, authorModule, backgroundArticleModule, catToolModule, channelModule, channelizerModule, ChipWidgetModule, ctaButtonModule, galleryModule, imageModule, jobModule, kimModule, linksBoxModule, lockingModule, contentModule, textContentBlockModule, partnerModule, seoWhitelistModule, socialModule, taggedPageModule, videoModule]; 
